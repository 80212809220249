<template>
  <div id="NewsDetail">
    <ul class="news-header">
      <li>
        {{ newsContent.noticeTitle }}
      </li>
      <li>{{ newsContent.releaseTime }}</li>
    </ul>
    <div v-if="newsContent.noticeForm === '0'" class="news-content" v-html="newsContent.noticeDescribe">
    </div>
    <a v-else :href="newsContent.noticeDescribe"  class="news-content">
      {{ newsContent.noticeDescribe }}
    </a>
    <ul class="news-action" v-if="newsContent.noticeDescribe">
      <!-- <li class="news-reading-num">
        {{ readingNum }}
      </li> -->
      <li class="news-action-box">
        <ul class="news-action-item">
          <!-- <li @click="showShare = true">
            <van-icon name="share-o" class="news-action-icon" />
            <span class="news-action-label">分享</span>
          </li>
          <li>
            <span class="iconfont icon-shoucang news-action-icon"></span>
            <span class="news-action-label">收藏</span>
          </li> -->

          <li class="news-action-item news-reading-num">
            {{ readingNum }}
          </li>
        </ul>
        <ul class="news-action-item">
          <li @click="noticeLike(null, !isCollected)">
            <van-icon
              class="news-action-icon"
              :name="isCollected ? 'good-job' : 'good-job-o'"
            />
            <span class="news-action-label">{{ giveThumbsNum }}</span>
          </li>
        </ul>
      </li>
    </ul>

    <van-action-sheet
      v-model:show="showShare"
      :actions="shareOptions"
      cancel-text="取消"
      close-on-click-action
      @cancel="onCancel"
      @open="queryWxConfig"
      @select="selectShareItem"
    />
  </div>
</template>

<script>
import { selectNoticeById, likeThisNotice } from '@/api/notice';
export default {
  data() {
    return {
      readingQuantity: undefined,
      giveThumbsNum: undefined,
      isCollected: false,
      newsContent: '',
      showShare: false,
      shareOptions: [
        { name: '发送给朋友', value: 'appMessage' },
        { name: '分享到朋友圈', value: 'timeline' }
      ],
      noticeId: undefined,
      noticeDescribe: ''
    }
  },
  computed: {
    readingNum() {
      return this.newsContent.pageViews <= 9999 ?
      '阅读' + ' ' + this.newsContent.pageViews :
      '阅读' + ' ' + (this.newsContent.pageViews/10000).toFixed(1) + '万';
    }
  },
  created() {
    this.noticeId = this.$route.query.noticeId;
  },
  updated() {

  },
  mounted() {
    this.initData();
    this.noticeLike({ noticeId: this.noticeId });
    // let href = window.location.href;
    // this.$getWxApplets(href, this, true);
  },
  methods: {
    onCancel() {
      this.showShare = false;
    },
    initData() {
      this.$forceUpdate();
      return new Promise((resolve, reject) => {
        selectNoticeById(this.noticeId).then(res => {
          let { status, resultData } = res;
          if (status === 'complete') {
            this.newsContent = resultData;
            // this.noticeDescribe = resultData.noticeDescribe;
            // this.readingQuantity = resultData.pageViews;
          }
          resolve(resultData);
        }).catch(e => {
          reject(new Error(e));
        });
      });
    },
    noticeLike(argument, state) {
      let params = {
        noticeId: this.noticeId,
        isLike: state
      }
      likeThisNotice(argument? argument : params).then((res) => {
        if (res.status === 'complete') {
          this.isCollected = res.resultData.isLike;
          this.giveThumbsNum = res.resultData.likeCount;
        }
      })
    },
    queryWxConfig() {
      // let href = window.location.href;
      // this.$getWxApplets(href, this, true);
    },
    selectShareItem(item) {
      console.log(item, '选中')
      // wx.ready(function() {
        switch(item.value) {
          // 分享朋友
          case 'appMessage':
            wx.updateAppMessageShareData({
              title: 'test111', // 分享标题
              desc: 'desc222', // 分享描述
              link: 'link333', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: 'imgUrl444', // 分享图标
              success: function () {
                alert('分享朋友---链接已生成')
              }
            })
            break;
          // 分享朋友圈
          case 'timeline':
            wx.updateTimelineShareData({
              title: '', // 分享标题
              desc: '', //分享描述
              link: '', // 分享链接
              imgUrl: '', // 分享图标
              success: function () {
                alert('分享朋友圈---链接已生成')
              }
            })
            break;
        }
      // });
    }
  }
}
</script>

<style lang="less">
#NewsDetail {
  width: 100%;
  height: 100vh;
  font-size: 14px;
  overflow: auto;
  color: #333;
  background-color: #fff;
  .news-header {
    padding: 0 15px;
    box-sizing: border-box;
    li:first-child {
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: 500;
    }
    li:last-child {
      padding-top: 10px;
      color: #C8C8C8;
    }
  }
  .news-content {
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
    img {
      width: 100% !important;
    }
  }
  .news-action {
    // position: fixed;
    // bottom: 50px;
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
    margin-bottom: 20px;
    .news-reading-num {
      color: #C8C8C8;
    }
    .news-action-box {
      padding-top: 10px;
      display: flex;
      justify-content: space-between;
      .news-action-item {
        color: #57627C;
        li {
          display: flex;
          align-items: center;
          // flex-direction: column; // 没做分享/收藏注释掉
          text-align: center;
        }
        &:first-child {
          display: flex;
          li:last-child {
            // padding-left: 20px; // 没做分享/收藏注释掉
          }
        }
        .news-action-icon {
          font-size: 20px;
        }
        .news-action-label {
          padding-top: 3px;
        }
      }
    }
  }
  ::v-deep .van-action-sheet, .van-action-sheet__cancel {
    color: #222;
  }
  ::v-deep .van-action-sheet__item:first-child {
    border-bottom: 1px solid #F3F3F3;
  }
}
</style>